import auth0 from 'auth0-js';
import Vue from 'vue';
import Store from "./plugins/store";

// exchange the object with your own from the setup step above.
let webAuth = new auth0.WebAuth({
    domain: window.OAUTH_URL || 'https://test-login.t-2.net/',
    clientID: window.OAUTH_CLIENT_ID || 'ott',
    // make sure this line is contains the port: 8080
    redirectUri: window.OAUTH_REDIRECT_URL || 'http://localhost:8080/callback',
    // we will use the api/v2/ to access the user information as payload
    // audience: 'https://' + 'your_auth0_domain' + '/api/v2/',
    responseType: 'token',
    scope: 'email phone profile' // define the scopes you want to use
});

let auth = new Vue({
    computed: {},
    methods: {
        login(redirectUrl = null) {
            if (redirectUrl !== null) {
                webAuth.baseOptions.redirectUri = redirectUrl;
            }
            webAuth.authorize();
        },
        logout() {
            return new Promise(() => {
                const logoutUrl = window.OAUTH_URL
                    ? window.OAUTH_URL + "logout?redirect_uri=" + window.LOGOUT_REDIRECT_URL
                    : "https://test-login.t-2.net/logout?redirect_uri=http://localhost:8080";
                window.location.replace(logoutUrl);
                Store.commit('auth/DESTROY_AUTH');
                Store.commit('promoCode/DESTROY_PROMO');
                Store.commit('user/DESTROY_USER');
            })
        },
        handleAuthentication() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((err, authResult) => {
                    if (authResult && authResult.accessToken) {
                        Store.dispatch('auth/setAuth', authResult);
                        resolve();
                    } else if (err) {
                        this.logout();
                        reject(err);
                    }
                });
            });
        }
    }
})

export default {
    install: function (Vue) {
        Vue.prototype.$auth = auth;
    }
}
