<template>
  <v-app id="checkout">
    <div class="appSteps">
      <header class="landingHeader">
        <div class="wrapper flex">
          <router-link :to="{name: 'Home'}" class="logo">T2 storitve</router-link>
          <Steps/>
        </div>
      </header>
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>
    <v-footer app absolute>
      <Footer/>
    </v-footer>
  </v-app>
</template>

<script>
import Steps from "@/components/Steps";
import Footer from "@/components/Footer";

export default {
  name: "Checkout",
  components: {Footer, Steps}
}
</script>

<style scoped>

</style>