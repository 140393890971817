import Vue from 'vue';
import VueRouter from 'vue-router';
import callback from "../views/callback";
import Store from "../plugins/store";
import paymentDone from "../views/paymentDone";
import {purchaseTypes} from '../enumerations/purchaseTypes';

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/landing/Home.vue'),
        authorized: false,
        meta: {layout: 'Landing'}
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "about" */ '../views/landing/Terms.vue'),
        meta: {layout: 'Landing'}
    },
    {
        path: '/privacy-policy',
        name: 'Information',
        component: () => import(/* webpackChunkName: "about" */ '../views/landing/Informations.vue'),
        meta: {layout: 'Landing'}
    },
    {
        path: '/cookies',
        name: 'Cookies',
        component: () => import(/* webpackChunkName: "about" */ '../views/landing/Cookies.vue'),
        meta: {layout: 'Landing'}
    },
    {
        path: '/callback',
        name: 'callback',
        component: callback
    },
    // {
    //     path: '/hisense/callback',
    //     name: 'HisenseCallback',
    //     component: callback
    // },
    {
        path: '/payment-done',
        name: 'payment-done',
        component: paymentDone
    },

    // {
    //     path: '/hisense',
    //     name: 'Hisense',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/landing/subpages/Hisense.vue'),
    //     meta: {layout: 'Landing'},
    // },
    //     {
    //     path: '/promocija',
    //     name: 'Promotion',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/landing/subpages/Promotion.vue'),
    //     meta: {layout: 'Landing'},
    // },
    {
        path: '/promo-code/step-one',
        name: 'PromoStep_1',
        component: () => import(/* webpackChunkName: "about" */ '@/views/promoCode/StepOne.vue'),
        meta: {layout: 'Checkout', stepIndex: 1, type: purchaseTypes.Promo},
    },
    {
        path: '/promo-code/step-two',
        name: 'PromoStep_2',
        component: () => import(/* webpackChunkName: "about" */ '@/views/promoCode/StepTwo.vue'),
        meta: {layout: 'Checkout', stepIndex: 2, type: purchaseTypes.Promo},
    },
    {
        path: '/promo-code/step-three',
        name: 'PromoStep_3',
        component: () => import(/* webpackChunkName: "about" */ '@/views/promoCode/StepThree.vue'),
        meta: {layout: 'Checkout', stepIndex: 3, type: purchaseTypes.Promo},
    },
    {
        path: '/package-select/step-one',
        name: 'Package_select_step_1',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkout/PackageSelectStepOne.vue'),
        meta: {layout: 'Checkout', stepIndex: 1, type: purchaseTypes.Regular},
    },
    {
        path: '/package-select/step-two',
        name: 'Package_select_step_2',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkout/PackageSelectStepTwo.vue'),
        meta: {layout: 'Checkout', stepIndex: 2, type: purchaseTypes.Regular},
        beforeEnter: (to, from, next) => {
            if (Store.getters['offer/getSelectedPackage'] === null) {
                next('/package-select/step-one');
            }
            next();
        }
    },
    {
        path: '/package-select/step-three',
        name: 'Package_select_step_3',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkout/PackageSelectStepThree.vue'),
        meta: {layout: 'Checkout', stepIndex: 3, type: purchaseTypes.Regular},
        beforeEnter: (to, from, next) => {
            if (Store.getters['offer/getSelectedPackage'] === null) {
                next('/package-select/step-one');
            }
            next();
        }
    },
    {
        path: '/package-select/step-four',
        name: 'Package_select_step_4',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkout/PackageSelectStepFour.vue'),
        meta: {layout: 'Checkout', stepIndex: 4, type: purchaseTypes.Regular},
        beforeEnter: (to, from, next) => {
            if (Store.getters['offer/getSelectedPackage'] === null) {
                next('/package-select/step-one');
            }
            next();
        }
    },
    {
        path: '/package-select/step-five',
        name: 'Package_select_step_5',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkout/PackageSelectStepFive.vue'),
        meta: {layout: 'Checkout', stepIndex: 5, type: purchaseTypes.Regular},
        beforeEnter: (to, from, next) => {
            if (Store.getters['offer/getSelectedPackage'] === null) {
                next('/package-select/step-one');
            }
            if (Store.getters['offer/getAgreeWithTerms'] === false) {
                next('/package-select/step-four');
            }
            next();
        }
    },
    {
        path: '/app',
        name: 'App_home',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Home.vue'),
        meta: {layout: 'Application'},
        beforeEnter: (to, from, next) => {
            if (from.name === 'payment-done') {
                return next();
            }
            Store.dispatch('loader/toggleShow', true);
            Store.dispatch('user/getUser')
                .then(() => {
                    Store.dispatch('loader/toggleShow', false);
                    if (!Store.getters['user/subscriptions'].length) {
                        if (Store.getters['user/getPurchaseType'] === purchaseTypes.Promo) {
                            return next('/promo-code/step-one');
                        }
                        next('/package-select/step-one');
                    }
                    next();
                });
        }
    },
    {
        path: '/app/watch-tv',
        name: 'App_watch',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/WatchTv.vue'),
        meta: {layout: 'Application'}
    },
    {
        path: '/app/subscription',
        name: 'App_subscription',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Subscription.vue'),
        meta: {layout: 'Application'}
    },
    {
        path: '/app/profile',
        name: 'App_profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Profile.vue'),
        meta: {layout: 'Application'}
    },
    {
        path: '/app/my-receivers',
        name: 'App_receivers',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/MyReceivers.vue'),
        meta: {layout: 'Application'}
    },
    {
        path: '*',
        redirect: '/app',
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name == 'Terms') {
        return next()
    }
    if (to.name == 'Information') {
        return next()
    }
    if (to.name == 'Cookies') {
        return next()
    }
    // if (to.name == 'Hisense') {
    //     return next()
    // }
     if (to.name == 'Promotion') {
        return next();
    }
    if (to.name === 'Home') {
        return next();
    }
    if (to.path === '/package-select/null') {
        return next('/payment-done');
    }

    if (to.name == 'callback' || to.name == 'HisenseCallback' || to.name == 'Home') {
        return next();
    }

    if (Store.getters['auth/isAuthenticated']) { // if authenticated allow access
        if (!Store.getters['user/subscriptions'].length && from.meta.stepIndex && to.name === 'Home') {
            return next();
        }
        if (!Store.getters['user/subscriptions'].length && !to.meta.stepIndex) {
            Store.dispatch('user/getUser')
                .then(() => {
                    if (Store.getters['user/subscriptions'].length) {
                        return next('/app');
                    }
                    if (Store.getters['user/getPurchaseType'] === purchaseTypes.Promo) {
                        return next('/promo-code/step-one');
                    }
                    return next('/package-select/step-one');
                });
        }

        if (!Store.getters['user/subscriptions'].length && to.meta.stepIndex) {
            if (Store.getters['user/getPurchaseType'] === to.meta.type) {
                return next();
            }
            if (Store.getters['user/getPurchaseType'] === purchaseTypes.Promo) {
                return next('/promo-code/step-one');
            }
            return next('/package-select/step-one');
        }
        if (Store.getters['user/subscriptions'].length && (to.meta.stepIndex || to.name === 'Home')) {
            return next('/app');
        }
        return next();
    }

    next('/');
});

export default router;
