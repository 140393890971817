<template>
  <div class="homeWelcome">
    <div class="top">
      <div class="wrapper">
        <h1>
          Hvala, da ste se odločili za naročilo T-2 TV pretočne televizije. <br />Prijeten ogled želimo!
        </h1>
        <v-btn color="primary" depressed class="orangeButtonL" @click="toApp">
          NADALJUJTE
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "callback",
  mounted() {
    setTimeout(() => {
      this.toApp();
    }, 10000);
  },
  methods: {
    toApp() {
      this.$store
        .dispatch("user/getUser")
        .then(() => {
          this.$router.push({ name: "App_home" });
        })
        .catch((e) => console.error(e));
    },
  },
};
</script>

<style scoped></style>
