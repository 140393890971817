import timeLapseFunction from "../services/timeLapseService";

// State object
const state = {
  timeLapseFunction: null
};

// Getter functions
const getters = {
  getTimeLapseFunction(state) {
    return state.timeLapseFunction;
  }
};

// Actions
const actions = {
  getTimeLapseFunction({ commit }) {
      timeLapseFunction.timeLapseFunction().then((res) => {
        commit('TIME_LAPSE_FUNCTION', res);
      }).catch((error) => {
        throw new Error(error)
      });
    },
};
// Mutations
const mutations = {
  TIME_LAPSE_FUNCTION(state, value) {
    state.timeLapseFunction = value;
  }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
