<template>
  <div>
  <div v-if="purchaseType === purchaseTypes.Promo">
    <ul class="steps">
      <li class="title"><span>Nakup paketa</span></li>
      <li @click="goToStep(1)" :class="{selected: (activeRoute.path.startsWith('/promo-code/step-one')), completed: isCompleted(1)}">
        <span>1</span><small class="line">&nbsp;</small></li>
      <li @click="goToStep(2)" :class="{selected: (activeRoute.path.startsWith('/promo-code/step-two')), completed: isCompleted(2)}">
        <span>2</span><small class="line">&nbsp;</small></li>
      <li @click="goToStep(3)" :class="{selected: (activeRoute.path.startsWith('/promo-code/step-three')), completed: isCompleted(3)}">
        <span>3</span><small class="line">&nbsp;</small></li>
    </ul>
  </div>
  <div v-else>
    <ul class="steps">
      <li class="title"><span>Nakup paketa</span></li>
      <li @click="goToStep(1)" :class="{selected: (activeRoute.path.startsWith('/package-select/step-one')), completed: isCompleted(1)}">
        <span>1</span><small class="line">&nbsp;</small></li>
      <li @click="goToStep(2)" :class="{selected: (activeRoute.path.startsWith('/package-select/step-two')), completed: isCompleted(2)}">
        <span>2</span><small class="line">&nbsp;</small></li>
      <li @click="goToStep(3)" :class="{selected: (activeRoute.path.startsWith('/package-select/step-three')), completed: isCompleted(3)}">
        <span>3</span><small class="line">&nbsp;</small></li>
      <li @click="goToStep(4)" :class="{selected: (activeRoute.path.startsWith('/package-select/step-four')), completed: isCompleted(4)}">
        <span>4</span><small class="line">&nbsp;</small></li>
      <li :class="{selected: (activeRoute.path.startsWith('/package-select/step-five')), completed: isCompleted(5)}">
        <span>5</span><small class="line">&nbsp;</small></li>
    </ul>
  </div>
</div>
</template>

<script>
import { purchaseTypes } from '../enumerations/purchaseTypes';
export default {
  name: "Steps",
  data() {
    return {
      activeRoute: null,
      purchaseTypes
    };
  },
  created() {
    this.activeRoute = this.$route;
  },
  computed: {
    purchaseType() {
      return this.$store.getters["user/getPurchaseType"];
    },
  },
  watch: {
    '$route'(to) {
      this.activeRoute = to;
    }
  },
  methods: {
    isCompleted: function (step) {
      let meta = this.activeRoute.meta;
      if(!meta) return false;
      if(!meta.stepIndex) return false;

      return (meta.stepIndex > step);
    },
    goToStep(index) {
      if(this.isCompleted(index)){
        if(this.purchaseType === purchaseTypes.Regular) this.$router.push({ name: 'Package_select_step_' + index});
        if(this.purchaseType === purchaseTypes.Promo) this.$router.push({ name: 'PromoStep_' + index});
      }
    }
  }
}
</script>

<style scoped>

</style>