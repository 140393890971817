import { purchaseTypes } from "../enumerations/purchaseTypes";
import userService from "../services/userService";

const state = {
    user: null,
    subscriptions: [],
    ottData: null,
    purchaseType: purchaseTypes.Regular
};

const getters = {
    user(state) {
        return state.user;
    },
    subscriptions(state) {
        return state.subscriptions;
    },
    address(state) {
        if (state.user === null) {
            return {
                street: null,
                city: null,
                postCode: null,
                houseNumber: null
            };
        }
        const splited = state.user.address.split(', ');
        return {
            street: splited[0] !== undefined ? splited[0] : null,
            city: splited[1] !== undefined ? splited[1] : null,
            postCode: splited[2] !== undefined ? splited[2] : null,
            houseNumber: state.user.hisnaStevilka
        };
    },
    ottData(state) {
        return state.ottData;
    },
    getPurchaseType(state) {
        return state.purchaseType;
    }
};

const mutations = {
    SET_USER(state, value) {
        state.user = value;
    },
    SET_SUBSCRIPTIONS(state, value) {
        state.subscriptions = value;
    },
    SET_OTT(state, value) {
        state.ottData = value;
    },
    SET_PURCHASE_TYPE(state, value) {
        state.purchaseType = value;
    },
    DESTROY_USER(state) {
        state.user = null,
        state.subscriptions = [],
        state.ottData = null,
        state.purchaseType = purchaseTypes.Regular
    },
};

const actions = {
    getUser({commit}) {
        return Promise.all([
            userService.info()
                .then((res) => {
                    commit('SET_USER', res);
                })
                .catch((error) => {
                    throw new Error(error)
                }),

            userService.subscriptions()
                .then((res) => {
                    commit('SET_SUBSCRIPTIONS', res.data);
                })
                .catch((error) => {
                    throw new Error(error)
                }),
        ]);
    },
    getOttData({commit}) {
        userService.ottData()
            .then(res => {
                commit('SET_OTT', res);
            });
    },
    setPurchaseType({ commit }, purchaseType) {
        commit("SET_PURCHASE_TYPE", purchaseType);
    },
    destroyUser({ commit }) {
        commit("DESTROY_USER");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
