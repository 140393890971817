// State object
const state = {
    code: null,
    valid: false,
};
// Getters
const getters = {
    getCode(state) {
        return state.code;
    },
    getValid(state) {
        return state.valid;
    },
};
// Mutations
const mutations = {
    SET_CODE(state, value) {
        state.code = value;
    },
    SET_VALID(state, value) {
        state.valid = value;
    },
    DESTROY_PROMO(state) {
        state.code = null;
        state.valid = false;
    },
};
// Actions
const actions = {
    setCode({commit}, code) {
        commit("SET_CODE", code);
    },
    setValid({commit}, valid) {
        commit("SET_VALID", valid);
    },
    destroyPromo({commit}) {
        commit("DESTROY_PROMO");
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
