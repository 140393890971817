/* This is template for store module.  */

// State object
const state = {};

// Getter functions
const getters = {};

// Actions
const actions = {};
// Mutations
const mutations = {};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
