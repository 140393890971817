import priceService from "../services/priceService";
// State object
const state = {
  package: null,
  additionalPackages: [],
  timeLapsed: null,
  horizont: null,
  totalPrice: 0,
  totalPayPrice: 0,
  agreeWithTerms: false
};

// Getter functions
const getters = {
  getSelectedPackage(state) {
    return state.package;
  },
  getSelectedAdditionalPackages(state) {
    return state.additionalPackages;
  },
  getTotalPrice(state) {
    return state.totalPrice;
  },
  getTotalPayPrice(state) {
    return state.totalPayPrice;
  },
  getTimeLapseFunction(state) {
    return state.timeLapsed;
  },
  getHorizont(state) {
    return state.horizont;
  },
  getAgreeWithTerms(state) {
    return state.agreeWithTerms;
  }
};

// Actions
const actions = {
  setSelectedPackage({commit}, selectedPackage) {
    commit('SET_SELECTED_PACKAGE', selectedPackage);
  },
  setAdditionalPackages({commit}, additionalPackages) {
    commit('SET_ADDITIONAL_PACKAGES', additionalPackages);
  },
  setTotalPrice({commit}, value) {
    commit('SET_TOTAL_PRICE', value);
  },
  setTotalPayPrice({commit}, value) {
    commit('SET_TOTAL_PAY_PRICE', value);
  },
  setTimeLapseFunction({commit}, value) {
    commit('SET_TIME_LAPSE_FUNCTION', value);
  },
  setHorizont({commit}, value) {
    commit('SET_HORIZONT', value);
  },
  setAgreeWithTerms({commit}, value) {
    commit('SET_AGREE_WITH_TERMS', value);
  },
  calculateTotalPrice({commit}, params) {
    priceService.calculateTotalPrice(params).then((res) => {
      commit('SET_TOTAL_PRICE', res.cena);
      commit('SET_TOTAL_PAY_PRICE', res.cenaZaPlacilo);
    }).catch((error) => {
      throw new Error(error)
    });
  }
};
// Mutations
const mutations = {
  SET_SELECTED_PACKAGE(state, value) {
    state.package = value
  },
  SET_ADDITIONAL_PACKAGES(state, value) {
    state.additionalPackages = value
  },
  SET_TOTAL_PRICE(state, value) {
    state.totalPrice = value
  },
  SET_TOTAL_PAY_PRICE(state, value) {
    state.totalPayPrice = value
  },
  SET_TIME_LAPSE_FUNCTION(state, value) {
    state.timeLapsed = value
  },
  SET_HORIZONT(state, value) {
    state.horizont = value
  },
  SET_AGREE_WITH_TERMS(state, value) {
    state.agreeWithTerms = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
