import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "../router";

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = window.APP_API_URL || "https://test-api.t-2.net/";

export function pushInterceptor(axios, store) {
    axios.interceptors.request.use((config) => {
        store.dispatch('loader/toggleShow', true);
        // config.headers.Accept = "application/json";
        if (store.getters['auth/isAuthenticated']) { // if authenticated allow access
            config.headers.Authorization = `Bearer ${store.getters['auth/getAccessToken']}`
        }
        return config;
    }, error => {
        store.dispatch('loader/toggleShow', false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        store.dispatch('loader/toggleShow', false);
        return response;
    }, error => {
        store.dispatch('loader/toggleShow', false);
        if (error.response.status === 401) {
            store.dispatch('auth/destroyAuth');
            router.push({name: 'Home'});
            return Promise.reject(error);
        }
        if (error.response.status === 400) {
            store.dispatch('alert/setMessage', 'Prišlo je do napake. Prosimo, poizkusite ponovno.');
            store.dispatch('alert/toggleShow', true);
            return Promise.reject(error);
        }
        store.dispatch('alert/setMessage', error.response.data.message);
        store.dispatch('alert/toggleShow', true);
        return Promise.reject(error);
    });
}
