const state = {
    show: false
};

const getters = {
    show(state) {
        return state.show
    },
};

const mutations = {
    toggleShow(state, value) {
        state.show = value;
    },
};

const actions = {
    toggleShow(context, value) {
        context.commit('toggleShow', value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
