<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="700px"
  >
    <v-card class="popUp form warningPopUp">
      <div class="popUpHeader">
        <h3 class="margin20">Opozorilo</h3>
        <a
          class="closeButton"
          @click="close"
        >
          <span class="warpit_icon_x"></span>
        </a>
      </div>
      <hr class="gradient">
      <v-card-text>
        <v-container>
          <div class="flex">
            <div class="icon">
              <v-img
                src="@/images/app/warning.svg"
              ></v-img>
            </div>
            <div class="content">
              <h3>{{ title }}</h3>
              <hr class="short">
              <p>{{ message }}</p>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <hr class="gradient margin">
      <v-card-actions class="buttons">
        <v-btn
          class="primary saveButton"
          text
          @click="close"
        >
          OK
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Alert",
  components: {},
  data: () => ({}),
  computed: {
    show() {
      return this.$store.getters['alert/show'];
    },
    message() {
      return this.$store.getters['alert/message'];
    },
    title() {
      return this.$store.getters['alert/title'];
    }
  },
  methods: {
    close() {
      this.$store.dispatch('alert/toggleShow', false);
      this.$store.dispatch('alert/setMessage', '');
      this.$store.dispatch('alert/setTitle', '');
    }
  }
}
</script>

<style scoped>

</style>
