<template>
  <div class="cookies">
    <p>
      <strong>Politika piškotkov</strong>
      Ta spletna stran uporablja piškotke. Poleg obveznih, uporabljamo tudi piškotke za analizo obiska spletnih strani
      in izboljšanje uporabniške izkušnje. S soglasjem »strinjam se« dovoljujete vse namene uporabe. Posamezne namene
      lahko nastavite
      <router-link to="/cookies">tukaj.</router-link>
    </p>

    <v-expand-transition>
      <v-card
        v-show="expand"
        class="choose"
      >
        <v-checkbox
          v-model="checkbox"
          :label="`Sistemski piškotki (vedno podeljeni)`"
        ></v-checkbox>
        <v-checkbox
          v-model="checkbox1"
          :label="`Piškotki spletne analitike`"
        ></v-checkbox>
        <v-checkbox
          v-model="checkbox2"
          :label="`Piškotki družbenih omrežij in oglaševanja`"
        ></v-checkbox>
        <v-btn
          color="primary"
          depressed
          class="orangeButtonL"
          @click="acceptCookies"
        >
          SHRANI IN ZAPRI
        </v-btn>
      </v-card>
    </v-expand-transition>
    <div class="flex">
      <v-btn
        color="primary"
        depressed
        class="orangeButtonL"
        @click="acceptAll"
      >
        IZBERITE VSE
      </v-btn>
      <v-btn
        color="primary"
        depressed
        class="orangeButtonL"
        @click="acceptMandatory"
        outlined
      >
        IZBERITE OBVEZNE
      </v-btn>
      <v-btn
        class="edit"
        @click="expand = !expand"
        :ripple="false"
        link
        color="primary"
        plain
        small
      >
        <span class="warpit_icon_settings"></span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  data() {
    return {
      expand: false,
      checkbox: true,
      checkbox1: false,
      checkbox2: false,
    }
  },
  methods: {
    acceptCookies() {
      this.$store.dispatch('cookie/setAccepted', true);
    },
    acceptAll() {
      this.$store.dispatch('cookie/setAll', true);
      this.$store.dispatch('cookie/setAccepted', true);
    },
    acceptMandatory() {
      this.$store.dispatch('cookie/setMandatory', true);
      this.$store.dispatch('cookie/setAccepted', true);
    }
  }
}
</script>

<style scoped>

</style>
