import Vue from 'vue';
import additionalPackagesChannels from "../config/additionalPackagesChannels";

export default {
    async additionalPackages() {
        const response = await Vue.axios.get('prikljucek/storitve/naborTvStoritev?idTehnoloskaOsnova=44');
        response.data.naborTvProgramov.forEach((element) => {
            const additionalChannels = additionalPackagesChannels.find(channel => channel.id === element.id);
            element.additionalPackageChannels = additionalChannels || [];
            element.disabled = false;
        });

        return response.data;
    }
}
