const state = {
    show: false,
    message: '',
};

const getters = {
    show(state) {
        return state.show;
    },
    message(state) {
        return state.message;
    },
};

const mutations = {
    SET_SHOW(state, show) {
        state.show = show;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
};

const actions = {
    toggleShow({commit}, show) {
        commit('SET_SHOW', show);
    },
    setMessage({commit}, message) {
        commit('SET_MESSAGE', message);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
