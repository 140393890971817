<template>

  <div class="wrapper flex">
    <div class="copy"><span>T-2 d.o.o.</span> · <a class="underline-hover" href="/terms">Splošni pogoji</a> · <a class="underline-hover" href="/privacy-policy">Politika zasebnosti</a> · <a class="underline-hover" href="/cookies">Politika piškotkov</a>
    </div>
    <v-img
        max-width="40"
        src="@/images/logo_T2_outline.svg"
    ></v-img>
  </div>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
