<template>
  <div>
    <alert></alert>
    <v-overlay z-index="500" :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <component :is="layout"></component>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";
import Checkout from "@/layouts/Checkout";
import Application from "@/layouts/Application";
import Alert from "./components/Alert";

export default {
  components: {Checkout, Landing, Application, Alert},
  data() {
    return {
      showMenu: false,
    }
  },
  mounted() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$store.dispatch('user/getUser').then(() => {
      }).catch((e) => console.error(e));
    }
  },
  computed: {
    routes: function () {
      return this.$router.options.routes;
    },
    layout: function () {
      if (this.$route.meta.layout) {
        if (this.$route.meta.layout === 'Landing') {
          return Landing;
        } else if (this.$route.meta.layout === 'Checkout') {
          return Checkout;
        } else if (this.$route.meta.layout === 'Application') {
          return Application;
        } else return null;
      }
      return Landing;
    },
    loader() {
      return this.$store.getters["loader/show"];
    }
  },
}
</script>

<style lang="scss">

</style>
