const state = {
    token: null,
    accessToken: null,
    expiresAt: null,
};

const getters = {
    getAccessToken(state) {
        return state.accessToken;
    },
    isAuthenticated(state) {
        if (state.expiresAt === null) {
            return false;
        }
        return new Date().getTime() < state.expiresAt;
    },
};

const mutations = {
    SET_AUTH(state, value) {
        state.token = value.token;
        state.expiresAt = value.expiresIn * 1000 + new Date().getTime();
        state.accessToken = value.accessToken;
    },
    DESTROY_AUTH(state) {
        state.token = null;
        state.expiresAt = null;
        state.accessToken = null;
    },
};

const actions = {
    setAuth({commit}, value) {
        commit('SET_AUTH', value);
    },
    destroyAuth({commit}) {
        commit('DESTROY_AUTH');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
