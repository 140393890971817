import Vue from 'vue';

export default {
    async update(formData) {
        const response = await Vue.axios.post('user/update', formData);
        return response.data;
    },
    async info() {
        const response = await Vue.axios.get('user/info');
        return response.data;
    },
    async ottData() {
        const response = await Vue.axios.get('stranka/ott');
        return response.data;
    },
    async subscriptions() {
        const response = await Vue.axios.get('/shop/subscriptions');
        return response.data;
    },
    async cards() {
        const response = await Vue.axios.get('/shop/cards');
        return response.data;
    },
    async createSubscription(formData) {
        const response = await Vue.axios.post(`stranka/ott/kreirajStranko`, formData);
        return response.data;
    },
}
