<template>
  <v-app id="landing" class="landingPage">
    <v-app-bar class="landingHeader">
      <div class="wrapper flex">
        <a href="/" class="logo">T2 storitve</a>
        <div class="topLinks">
          <div v-if="isAuthenticated">
            <div style="float: left">
              <a v-if="!haveSubscription" @click.prevent="toCard"><span
                class="warpit_icon_user"></span> <span class="text">Nadaljuj nakup</span>
              </a>
              <a v-else class="loginLink" @click.prevent="toCard"><span class="warpit_icon_user"></span>
                Vstopi v portal
              </a>
            </div>
            <div style="float: left; margin-left: 30px">
              <a @click.prevent="logout"><span class="warpit_icon_unlock"></span> <span class="text">Odjava</span></a>
            </div>
          </div>
          <a @click.prevent="login" class="loginLink underline-hover" v-else>Prijava / Registracija</a>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <Cookies v-if="showCookies"/>
    <v-footer app absolute>
      <Footer/>
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer";
import Cookies from "@/components/Cookies";

export default {
  name: "Landing",
  components: {Cookies, Footer},
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    haveSubscription() {
      return this.$store.getters['user/subscriptions'].length > 0;
    },
    showCookies() {
      return this.$store.getters['cookie/show'];
    }
  },
  methods: {
    login() {
      if (this.isAuthenticated) {
        this.$router.push({name: 'App_home'});
        return;
      }

      let redirectUrl = null;
      if (window.location.pathname.includes('hisense')) {
        const hisenseCallback = this.$router.options.routes.find(item => item.name === 'HisenseCallback');
        redirectUrl = window.location.origin + (hisenseCallback ? hisenseCallback.path : '/callback');
      }

      this.$auth.login(redirectUrl);
    },
    toCard() {
      this.$router.push({name: 'Package_select_step_1'});
    },
    logout() {
      this.$auth.logout();
      return;
    }
  }
}
</script>

<style scoped>

</style>
