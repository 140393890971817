import additionalPackageService from "../services/additionalPackageService";

// State object
const state = {
    additionalPackages: []
};

// Getter functions
const getters = {
    getAdditionalPackages(state) {
        return state.additionalPackages;
    },
};

// Actions
const actions = {
    getAdditionalPackages({commit}) {
        additionalPackageService.additionalPackages().then((res) => {
            commit('SET_ADDITIONAL_PACKAGES', res.naborTvProgramov);
        }).catch((error) => {
            throw new Error(error)
        });
    },
};
// Mutations
const mutations = {
    SET_ADDITIONAL_PACKAGES(state, value) {
        state.additionalPackages = value;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
