<template>
  <div></div>
</template>

<script>
import {purchaseTypes} from '../enumerations/purchaseTypes';

export default {
  name: "callback",
  computed: {
    purchaseType() {
      return this.$store.getters["user/getPurchaseType"];
    }
  },
  mounted() {
    this.$auth.handleAuthentication().then(() => {
      this.$store.dispatch('user/getUser').then(() => {
        this.$router.push(this.purchaseType === purchaseTypes.Promo ? {name: 'PromoStep_1'} : {name: 'App_home'});
      }).catch((e) => {
        this.$router.push({name: 'Home'})
        console.error(e)
      });
    });
  },
}
</script>

<style scoped>

</style>
