const state = {
    show: false,
    accepted: false,
    selectAll: false,
    selectMandatory: false
};

const getters = {
    show(state) {
        return !state.accepted;
    },
    accepted(state) {
        return state.accepted;
    }
};

const mutations = {
    SET_SHOW(state, show) {
        state.show = show;
    },
    SET_ACCEPTED(state, value) {
        state.accepted = value;
    },
    SET_ALL(state, value) {
        state.selectAll = value;
    },
    SET_MANDATORY(state, value) {
        state.selectMandatory = value;
    }
};

const actions = {
    toggleShow({commit}, show) {
        commit('SET_SHOW', show);
    },
    setAccepted({commit}, value) {
        commit('SET_ACCEPTED', value);
    },
    setAll({commit}, value) {
        commit('SET_ALL', value);
    },
    setMandatory({commit}, value) {
        commit('SET_MANDATORY', value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
