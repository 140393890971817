<template>
  <v-app id="application">
    <header class="landingHeader">
      <div class="wrapper flex">
        <router-link :to="{name: 'App_home'}" class="logo">T2 storitve - Aplikacija</router-link>
        <v-btn
          @click.stop="drawer = !drawer"
          class="menu"
        >
          <span class="warpit_icon_hamburger"></span>
        </v-btn>
      </div>
    </header>

    <v-main>
      <router-view>
      </router-view>
    </v-main>
    <v-footer app absolute>
      <Footer/>
    </v-footer>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      width="400"
    >
      <a

        @click.stop="drawer = !drawer"

      >
        <span class="warpit_icon_x"></span>
      </a>
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          exact-path
          class="underline-hover"
        >
          <v-list-item-icon>
            <span :class="item.icon"></span>
          </v-list-item-icon>

          <v-list-item-content @click="navigateTo(item)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Application",
  components: {Footer},
  data() {
    return {
      drawer: null,
      items: [
        {title: 'Domov', value: "home", icon: 'warpit_icon_Home', path: 'App_home'},
        {title: 'Glejte TV', value: "watch_tv", icon: 'warpit_icon_desktop', path: 'App_watch'},
        {
          title: 'Naročnina in paketi',
          value: "subscription_and_packages",
          icon: 'warpit_icon_gift',
          path: 'App_subscription'
        },
        {title: 'Moji TV sprejemniki', value: "my_receivers", icon: 'warpit_icon_electricity', path: 'App_receivers'},
        {title: 'Moj profil', value: "my_account", icon: 'warpit_icon_user', path: 'App_profile'},
        {title: 'Odjava', value: "logout", icon: 'warpit_icon_unlock', path: 'App_home'},
      ],
    }
  },
  mounted() {
    this.$store.dispatch('user/getOttData');
  },
  methods: {
    navigateTo(item) {
      if (item.value === 'logout') {
        this.$auth.logout();
        return;
      }
      this.$router.push({name: item.path}).catch(error => {
        if (error.name === 'NavigationDuplicated' && error.message.includes('Avoided redundant navigation to current location')) {
          this.drawer = false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
