import horizont from "../services/horizontService";

// State object
const state = {
  horizont: []
};

// Getter functions
const getters = {
  getHorizont(state) {
    return state.horizont;
  }
};

// Actions
const actions = {
  getHorizont({ commit }) {
      horizont.horizont().then((res) => {
        commit('SET_HORIZONT', res);
      }).catch((error) => {
        throw new Error(error)
      });
    },
};
// Mutations
const mutations = {
  SET_HORIZONT(state, value) {
    state.horizont = value;
  }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
