import Vue from 'vue';

export default {
    async calculateTotalPrice(array) {
        const formData = new FormData();
        array.forEach(element => {
            formData.append("storitve[]", element);
        });
        const response = await Vue.axios.post('stranka/ott/izracunajCeno', formData);
        return response.data;
    }
}
